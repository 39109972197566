import axios from 'axios'
import { Notification } from 'element-ui'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

request.interceptors.request.use(config => {
  return config
})

request.interceptors.response.use(response => {
  return response.data
}, (error) => {
  Notification.error('Request error: ' + error.message)
  return Promise.reject(error)
})

export default request
